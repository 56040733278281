import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Typography } from 'bdx-af-ui/core'

export interface IProfileIconComp {
  firstName: string
  lastName: string
  userId: number
}

interface IIconContainerProps {
  userId: number
}

const ProfileIconContainer = styled.div <IIconContainerProps>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-image: url(${({ userId }) => userId && `/assets/resources/images/profile/av${(userId % 9) + 1}.png`});
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ProfileIconLink = styled.a`
  &&: hover {
    text-decoration: none;
  }
`

const ProfileIcon: FunctionComponent<IProfileIconComp> = ({ firstName, lastName, userId }) => {
  const createIconText = () => `${firstName.split('')[0]}${lastName.split('')[0]}`.toUpperCase()
  return (
    <ProfileIconContainer userId={userId}>
      <ProfileIconLink
        href={`/assets/profile/${userId}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Typography
          color={'white'}
          marginBottom={'0'}
        >
          <strong>
            {createIconText()}
          </strong>
        </Typography>
      </ProfileIconLink>
    </ProfileIconContainer>
  )
}

export default ProfileIcon
