import { combineReducers } from '@reduxjs/toolkit'

import apiRequestsStatusesReducer, { IApiRequestsStatuses } from './ducks/apiRequestsStatuses'
import approvalsReducer, { IApprovalsState } from './ducks/approvals'
import configsReducer, { IConfigsState } from './ducks/configs'
import locationsReducer, { ILocationsState } from './ducks/locations'
import usersReducer, { IUsersState } from './ducks/users'
import KEYS from './storeKeys'

export interface IRootState {
  [KEYS.API_REQUESTS_STATUSES]: IApiRequestsStatuses
  [KEYS.APPROVALS]: IApprovalsState
  [KEYS.CONFIGS]: IConfigsState
  [KEYS.LOCATIONS]: ILocationsState
  [KEYS.USERS]: IUsersState
}

const rootReducer = combineReducers({
  [KEYS.API_REQUESTS_STATUSES]: apiRequestsStatusesReducer,
  [KEYS.APPROVALS]: approvalsReducer,
  [KEYS.CONFIGS]: configsReducer,
  [KEYS.LOCATIONS]: locationsReducer,
  [KEYS.USERS]: usersReducer
})

export default rootReducer
