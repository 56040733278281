import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'

const usersSelector = (state: IRootState) => state[KEYS.USERS]

export const getUsers = () => createSelector(
  usersSelector,
  (state) => state.users
)

export const getUserById = (userId: number) => createSelector(
  usersSelector,
  (state) => state.users
    .filter(user => user.id === userId)[0]
)
