import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'
import { SECTION_MAPPINGS } from '../../../utils/locations'
import { isEmpty } from '../../../utils/obj'
import { checkForEquality } from '../../../utils/array'

const approvalsSelector = (state: IRootState) => state[KEYS.APPROVALS]

export const getApprovals = () => createSelector(
  approvalsSelector,
  (state) => state.approvals
)

export const getInitialApprovals = () => createSelector(
  approvalsSelector,
  (state) => state.initialApprovals
)

export const getApprovalByUserId = (userId: number) => createSelector(
  approvalsSelector,
  (state) => state.approvals.filter(approval => approval.userId === userId)[0]
)

export const getApprovalLevels = () => createSelector(
  approvalsSelector,
  (state) => state.approvalLevels
)

export const getApprovalSectionsByLevel = (level: string) => createSelector(
  approvalsSelector,
  (state) => state.approvalLevels[level]
)

export const getLocationsByUserId = (userId: number) => createSelector(
  approvalsSelector,
  (state) => state.approvals.filter(approval => approval.userId === userId)[0]?.userApprovals?.locations || []
)

export const getUserApprovalLocationById = (userId: number, locationId: number) => createSelector(
  getLocationsByUserId(userId),
  (locations) => locations
    .filter(location => location.id === locationId)[0]
)

export const getUpdatedUserLocations = () => createSelector(
  approvalsSelector,
  (state) => state.updatedUserApprovalLocations
)

export const updatedUserApprovalLocationsExists = () => createSelector(
  approvalsSelector,
  (state) => (!isEmpty(state.updatedUserApprovalLocations))
)

export const getUpdatedApprovals = () => createSelector(
  approvalsSelector,
  (state) => state.updatedApprovals
)

export const updatedApprovalsExists = () => createSelector(
  approvalsSelector,
  (state) => (!isEmpty(state.updatedApprovals))
)

export const getUpdatedApprovalByUserId = (userId: string) => createSelector(
  approvalsSelector,
  (state) => state.updatedApprovals[userId] || {}
)

export const getIsAppReady = () => createSelector(
  approvalsSelector,
  (state) => (state.initialApprovals?.length > 0 && Boolean(state.approvalSettings))
)

export const getApprovalsEnabled = () => createSelector(
  approvalsSelector,
  (state) => state.approvalSettings.enabled
)

export const getApprovalCheckbox = (userId: number, level: string) => createSelector(
  approvalsSelector,
  (state) => {
    const levelSections: Array<string> = state.approvalLevels[level]
    if (state.updatedApprovals[userId] && levelSections.every(section => Object.prototype.hasOwnProperty.call(state.updatedApprovals[userId], SECTION_MAPPINGS[section]))) {
      return levelSections.every(section => state.updatedApprovals[userId][SECTION_MAPPINGS[section]])
    }
    const approval = state.approvals.filter(approval => approval.userId === userId)[0]
    if (approval?.userApprovals !== null) return levelSections.every(section => approval.userApprovals[SECTION_MAPPINGS[section]])
    return false
  }
)

export const isLocationsUnaltered = (userId: number) => createSelector(
  getUpdatedUserLocations(),
  getLocationsByUserId(userId),
  (locations, updatedLocations) => checkForEquality([locations.filter(location => (updatedLocations.findIndex(updatedLocation => updatedLocation.id === location.id) > -1)).length, locations.length, updatedLocations.length])
)
