import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'

const configsSelector = (state: IRootState) => state[KEYS.CONFIGS]

export const getAssetSections = () => createSelector(
  configsSelector,
  (state) => state.assetSections
)

export const getGroupId = () => createSelector(
  configsSelector,
  (state) => state.groupId
)
