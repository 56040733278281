import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import { IRootState } from '../../store/rootReducer'
import { saveUpdatedUserApprovalLocations, isLocationsUnaltered } from '../../store/ducks/approvals'
import { getRequestStatus, IRequestStatus } from '../../store/ducks/apiRequestsStatuses'
import SaveButton from '../Users/SaveButton'

export interface ISaveLocations {
  handleSaveCallback: () => void
  locationsUpdated: boolean
  userId: number
  requestStatus: IRequestStatus
  saveUpdatedUserApprovalLocations: typeof saveUpdatedUserApprovalLocations
}

export const SaveLocations: FunctionComponent<ISaveLocations> = ({ saveUpdatedUserApprovalLocations, handleSaveCallback, locationsUpdated, requestStatus, userId }) => {
  const [closePending, setClosePending] = useState(false)
  useEffect(() => {
    if (requestStatus.isPending === false && closePending) handleSaveCallback()
  }, [requestStatus])
  const handleSave = () => {
    saveUpdatedUserApprovalLocations({ userId })
    setClosePending(true)
  }
  return (
    <>
      <SaveButton
        disabled={!locationsUpdated}
        handleSaveAction={handleSave}
        isPending={requestStatus.isPending}
      />
    </>
  )
}

const mapStateToProps = (state: IRootState, props: Partial<ISaveLocations>) => ({
  locationsUpdated: !isLocationsUnaltered(props.userId)(state),
  requestStatus: getRequestStatus(saveUpdatedUserApprovalLocations.type)(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  saveUpdatedUserApprovalLocations
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SaveLocations)
