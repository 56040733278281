import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Button, GridContainer, GridItem, Modal, Paper, suggestion, Typography } from 'bdx-af-ui/core'
import { Close } from 'bdx-af-ui/icons'
import { colours } from 'bdx-af-ui/theme'

import { IRootState } from '../../store/rootReducer'
import { getGroupId } from '../../store/ducks/configs'
import { getUserById, IUser } from '../../store/ducks/users'
import { fetchAvailableLocations, getAvailableLocations, ILocation } from '../../store/ducks/locations'
import { getLocationsByUserId, editUserLocation, removeUserLocation, setUpdatedUserApprovalLocations } from '../../store/ducks/approvals'
import LocationSuggestion from './LocationSuggestion'
import LocationsList from './LocationsList'
import SaveLocations from './SaveLocations'

export interface ILocationsModal {
  availableLocations: Array<ILocation>
  setUpdatedUserApprovalLocations: typeof setUpdatedUserApprovalLocations
  editUserLocation: typeof editUserLocation
  removeUserLocation: typeof removeUserLocation
  fetchAvailableLocations: typeof fetchAvailableLocations
  groupId: number
  isOpen: boolean
  locations: Array<ILocation>
  onClose: () => void
  userId: number
  user: IUser
}

const Container = styled.div`
  background-color: white;
  overflow-y: scroll;
  min-height: 80vh;
  max-height: 80vh;
  min-width: 50vw;
  padding: 1rem;
`

const CloseIconContainer = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
`

const ModalFooter = styled.div`
  background-color: white;
  border-top: 1px solid ${colours.icons.main};
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
`

const CloseButtonContainer = styled.div`
  margin-left: 1rem;
`

export const LocationsModal: FunctionComponent<ILocationsModal> = ({ availableLocations, editUserLocation, fetchAvailableLocations, groupId, isOpen, locations, onClose, setUpdatedUserApprovalLocations, user }) => {
  const prevIsOpen = usePrevious(isOpen)
  const prevLocations = usePrevious(locations)
  useEffect(() => {
    if (isOpen && isOpen !== prevIsOpen) {
      fetchAvailableLocations({ groupId, userId: user.id })
      setUpdatedUserApprovalLocations({ locations })
    }
  }, [(locations.length > 0 || locations !== prevLocations), isOpen, user])
  const handleClose = () => {
    setUpdatedUserApprovalLocations({ locations: [] })
    onClose()
  }
  const handleLocationUpdate = (setSelectedOption: (arg0: suggestion) => void, selectSuggestion: suggestion) => {
    setSelectedOption(selectSuggestion)
    editUserLocation({ locationId: Number(selectSuggestion.value) })
  }
  return (
    <Modal open={isOpen} onClose={handleClose}>
      { isOpen && <Paper>
        <Container>
          <CloseIconContainer data-testid={'close-locations-modal'} onClick={handleClose}>
            <Close width={'20px'} height={'20px'} />
          </CloseIconContainer>
          <Typography align={'left'}>{`${user?.firstName} ${user?.lastName}: Assigned Locations`}</Typography>
          <GridContainer spacing={2}>
            <GridItem xs={12}>
              <LocationSuggestion
                availableLocations={availableLocations}
                handleLocationUpdate={handleLocationUpdate}
                placeholder={'Search locations...'}
                showSelection={false}
              />
            </GridItem>
            <LocationsList />
          </GridContainer>
        </Container>
        <ModalFooter>
          <SaveLocations
            handleSaveCallback={handleClose}
            userId={user.id}
          />
          <CloseButtonContainer>
            <Button
              onClick={handleClose}
              title={'Cancel location change'}
              variant={'text'}
            >
              Cancel
            </Button>
          </CloseButtonContainer>
        </ModalFooter>
      </Paper>
      }
    </Modal>
  )
}

function usePrevious(value: any) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const mapStateToProps = (state: IRootState, props: Partial<ILocationsModal>) => ({
  availableLocations: getAvailableLocations()(state),
  locations: getLocationsByUserId(props.userId)(state),
  user: getUserById(props.userId)(state),
  groupId: getGroupId()(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  fetchAvailableLocations,
  setUpdatedUserApprovalLocations,
  editUserLocation,
  removeUserLocation
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LocationsModal)
