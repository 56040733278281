import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'

import { IRootState } from '../../store/rootReducer'
import { editApprovalSection, getApprovalCheckbox } from '../../store/ducks/approvals'

const CheckboxContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export interface ISectionCheckbox {
  editApprovalSection: typeof editApprovalSection
  isChecked: boolean
  level: string
  userId: number
}

export const SectionCheckbox: FunctionComponent<ISectionCheckbox> = ({ editApprovalSection, isChecked, level, userId }) => {
  const [checked, setChecked] = useState(isChecked)
  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])
  const handleBoxClick = () => {
    setChecked(!checked)
    editApprovalSection({ userId, level })
  }
  return (
    <CheckboxContainer>
      <input
        checked={checked}
        onChange={handleBoxClick}
        type='checkbox'
      />
    </CheckboxContainer>
  )
}

const mapStateToProps = (state: IRootState, props: Partial<ISectionCheckbox>) => ({
  isChecked: getApprovalCheckbox(props.userId, props.level)(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  editApprovalSection
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SectionCheckbox)
