import { createAction, createReducer, ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'

export interface IApiRequestsStatuses {
  pending: {
    [key: string]: boolean
  }
  error: {
    [key: string]: string
  }
}
interface ISetRequestPending {
  key: string
  pending: boolean
}
interface ISetRequestError {
  key: string
  error: string
}

export const setRequestPending: ActionCreatorWithPayload<ISetRequestPending> = createAction('setRequestPending')
export const setRequestError: ActionCreatorWithPayload<ISetRequestError> = createAction('setRequestError')

const initialState: IApiRequestsStatuses = {
  pending: {},
  error: {}
}

const reducer = createReducer(initialState, {
  [setRequestPending.type]: (state, { payload }: PayloadAction<ISetRequestPending>) => {
    state.pending[payload.key] = payload.pending
  },
  [setRequestError.type]: (state, { payload }: PayloadAction<ISetRequestError>) => {
    state.error[payload.key] = payload.error
  }
})

export * from './selectors'
export default reducer
