import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import { fetchApprovals, editUserLocation, IEditUserLocation, removeUserLocation, IRemoveUserLocation, IFetchApprovals, IApprovals, IUpdatedApproval, IUpdatedApprovals, IEditApprovalSection, setApprovals, setInitialApprovals, setApproval, setUpdatedApproval, setUpdatedApprovals, getApprovalByUserId, editApprovalSection, saveApprovalSections, clearUpdatedApprovals, IApprovalSections, getUpdatedApprovalByUserId, getUpdatedApprovals, setUpdatedUserApprovalLocations, getUpdatedUserLocations, saveUpdatedUserApprovalLocations, ISaveUpdatedUserApprovalLocations, deleteUpdatedApproval, getApprovalSectionsByLevel, getLocationsByUserId, filterApprovalsByLocation, resetFilterApprovalsByLocation, IFilterApprovalsByLocation, getInitialApprovals } from './index'
import { ILocation, getLocationById, removeAvailableLocation, addAvailableLocation, setInitialAvailableLocations, getAvailableLocations, getInitialAvailableLocations, setAvailableLocations, setFilterUsersByLocationId, getFilterUsersByLocationId } from '../locations/index'
import { getGroupId } from '../configs/index'
import { getApprovals, setUserLocations, setUserApprovalSection } from '../../../api/approvals'
import { handleAsyncCall } from '../apiRequestsStatuses/sagas'
import { SECTION_MAPPINGS } from '../../../utils/locations'

export function* fetchApprovalsSaga(action: PayloadAction<IFetchApprovals>) {
  const approvals = yield call(getApprovals, action.payload.groupId)
  yield put(setInitialApprovals({ approvals }))
  yield put(setApprovals({ approvals }))
}

export function* editUserLocationSaga(action: PayloadAction<IEditUserLocation>) {
  const location = yield select(getLocationById(action.payload.locationId))
  const updatedLocations = yield select(getUpdatedUserLocations())
  yield all([
    put(setUpdatedUserApprovalLocations({ locations: [...updatedLocations, location] })),
    put(removeAvailableLocation({ location }))
  ])
}

export function* filterApprovalsByLocationSaga(action: PayloadAction<IFilterApprovalsByLocation>) {
  const approvals: Array<IApprovals> = yield select(getInitialApprovals())
  yield put(setFilterUsersByLocationId({ locationId: action.payload.locationId }))
  const filteredApprovals = approvals.filter(approval => approval.userApprovals !== null && approval.userApprovals.locations.filter(location => location.id === action.payload.locationId).length !== 0)
  if (filteredApprovals.length) {
    const updatedApprovals: IUpdatedApprovals = yield select(getUpdatedApprovals())
    const filteredUpdatedApprovals = Object.entries(updatedApprovals).reduce((acc: IUpdatedApprovals, [key, val]) => {
      if (filteredApprovals.find(filteredApproval => filteredApproval.userId === Number(key))) acc[key] = val
      return acc
    }, {})
    yield put(setApprovals({ approvals: filteredApprovals }))
    yield put(setUpdatedApprovals({ updatedApprovals: filteredUpdatedApprovals }))
  } else {
    yield put(setApprovals({ approvals: [] }))
    yield put(clearUpdatedApprovals())
  }
}

export function* resetFilterApprovalsByLocationSaga() {
  const approvals: Array<IApprovals> = yield select(getInitialApprovals())
  yield put(setApprovals({ approvals }))
  yield put(setFilterUsersByLocationId({ locationId: null }))
  yield put(clearUpdatedApprovals())
}

export function* removeUserLocationSaga(action: PayloadAction<IRemoveUserLocation>) {
  let location: ILocation
  const updatedLocations = yield select(getUpdatedUserLocations())
  const filteredUpdatedLocations = updatedLocations.reduce((acc: Array<ILocation>, updatedLocation: ILocation) => {
    if (updatedLocation.id !== action.payload.locationId) {
      acc.push(updatedLocation)
    } else {
      location = updatedLocation
    }
    return acc
  }, [])
  yield put(setUpdatedUserApprovalLocations({ locations: filteredUpdatedLocations }))
  yield put(addAvailableLocation({ location }))
}

export function* saveUserApprovalLocationsSaga(action: PayloadAction<ISaveUpdatedUserApprovalLocations>) {
  const groupId = yield select(getGroupId())
  const filteredLocationId = yield select(getFilterUsersByLocationId())
  const updatedLocations = yield select(getUpdatedUserLocations())
  const updatedApprovals = yield select(getUpdatedApprovalByUserId(String(action.payload.userId)))
  let userApprovals = {
    biddingDetails: false,
    compliance: false,
    fssSupplies: false,
    generalInfo: false,
    makeOffersAndBids: false,
    valuation: false,
    locations: updatedLocations
  }
  const approval = yield select(getApprovalByUserId(action.payload.userId))
  const approvalSections = { ...updatedApprovals } || { ...approval.userApprovals }
  if (approval.userApprovals !== null) {
    userApprovals = {
      ...approvalSections,
      locations: updatedLocations
    }
  }
  const data = {
    updatedDate: approval.updatedDate,
    userApprovals,
    userId: action.payload.userId
  }
  try {
    const updatedApproval: IApprovals = yield handleAsyncCall(saveUpdatedUserApprovalLocations.type, setUserLocations, data, groupId)
    const approvals = yield call(getApprovals, groupId)
    yield put(setApproval({ approval: updatedApproval }))
    yield put(setInitialApprovals({ approvals }))
    const newAvailableLocations = yield select(getAvailableLocations())
    yield put(setInitialAvailableLocations({ availableLocations: newAvailableLocations }))
    if (filteredLocationId !== null) yield put(filterApprovalsByLocation({ locationId: filteredLocationId }))
  } catch (err) {
    alert(JSON.parse(err.message).message)
    const availableLocations = yield select(getInitialAvailableLocations())
    const locations: Array<ILocation> = yield select(getLocationsByUserId(action.payload.userId))
    yield put(setAvailableLocations({ availableLocations }))
    yield put(setUpdatedUserApprovalLocations({ locations }))
  }
}

export function* editApprovalSectionSaga(action: PayloadAction<IEditApprovalSection>) {
  const [approval, updatedApproval, sections]: ([IApprovals, IUpdatedApproval, Array<string>]) = yield all([
    select(getApprovalByUserId(action.payload.userId)),
    select(getUpdatedApprovalByUserId(String(action.payload.userId))),
    select(getApprovalSectionsByLevel(action.payload.level))
  ])
  const approvalSections = sections.reduce((acc: IApprovalSections, section) => {
    if (Object.prototype.hasOwnProperty.call(updatedApproval, SECTION_MAPPINGS[section])) {
      acc[SECTION_MAPPINGS[section]] = !updatedApproval[SECTION_MAPPINGS[section]]
    } else {
      acc[SECTION_MAPPINGS[section]] = approval.userApprovals ? !approval.userApprovals[SECTION_MAPPINGS[section]] : true
    }
    return acc
  }, { ...updatedApproval })
  yield put(setUpdatedApproval({
    userId: action.payload.userId,
    approvalSections
  }))
  yield call(isUpdatedApprovalsDifferent)
}

function* isUpdatedApprovalsDifferent() {
  const updatedApprovals = yield select(getUpdatedApprovals())
  const updatedApprovalsKeys = Object.keys(updatedApprovals)
  for (const userId of updatedApprovalsKeys) {
    const intUserId = parseInt(userId)
    const userApprovals = yield select(getApprovalByUserId(intUserId))
    const shouldDeleteApproval = Object.keys(updatedApprovals[userId]).every(key => {
      if (userApprovals.userApprovals === null) return (updatedApprovals[userId][key] === false)
      return userApprovals.userApprovals[key] === updatedApprovals[userId][key]
    })
    if (shouldDeleteApproval) yield put(deleteUpdatedApproval({ userId: intUserId }))
  }
}

export function* saveApprovalSectionsSaga() {
  const { groupId, updatedApprovals }: { groupId: number, updatedApprovals: IUpdatedApprovals } = yield all({
    groupId: select(getGroupId()),
    updatedApprovals: select(getUpdatedApprovals())
  })
  const arrayOfUpdatedApprovals: IApprovals[] = []
  for (const userId of Object.keys(updatedApprovals)) {
    const approval = yield select(getApprovalByUserId(parseInt(userId)))
    const newUserApproval = {
      ...approval,
      userApprovals: {
        ...approval.userApprovals,
        ...updatedApprovals[userId]
      }
    }
    arrayOfUpdatedApprovals.push(newUserApproval)
  }
  try {
    const approvals = yield handleAsyncCall(saveApprovalSections.type, setUserApprovalSection, arrayOfUpdatedApprovals, groupId)
    const locationId = yield select(getFilterUsersByLocationId())
    yield all([
      put(clearUpdatedApprovals()),
      put(setInitialApprovals({ approvals }))
    ])
    if (locationId !== null) {
      yield put(filterApprovalsByLocation({ locationId }))
    } else {
      yield put(setApprovals({ approvals }))
    }
  } catch (err) {
    alert(JSON.parse(err.message).message)
    yield put(clearUpdatedApprovals())
  }
}

export default function* () {
  yield takeLatest(fetchApprovals.type, fetchApprovalsSaga)
  yield takeEvery(filterApprovalsByLocation.type, filterApprovalsByLocationSaga)
  yield takeEvery(resetFilterApprovalsByLocation.type, resetFilterApprovalsByLocationSaga)
  yield takeEvery(saveUpdatedUserApprovalLocations.type, saveUserApprovalLocationsSaga)
  yield takeEvery(saveApprovalSections.type, saveApprovalSectionsSaga)
  yield takeEvery(editApprovalSection.type, editApprovalSectionSaga)
  yield takeEvery(editUserLocation.type, editUserLocationSaga)
  yield takeEvery(removeUserLocation.type, removeUserLocationSaga)
}
