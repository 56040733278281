import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { GridContainer, GridItem, Paper, Typography } from 'bdx-af-ui/core'
import { Spinner } from 'bdx-af-ui/icons'
import styled from 'styled-components'

import { IRootState } from '../store/rootReducer'
import { IUser, setUsers } from '../store/ducks/users'
import { setAssetSections, setGroupId, IAssetSections } from '../store/ducks/configs'
import { IApprovalSettings, fetchApprovals, setApprovalSettings, getIsAppReady, getApprovalsEnabled } from '../store/ducks/approvals'
import { fetchAllLocations } from '../store/ducks/locations'
import { HeaderActions, SectionHeadings, UserList } from './Users/'

export interface IMainView {
  isAppReady: boolean
  approvalSettings: IApprovalSettings
  assetSections: IAssetSections
  approvalsEnabled: boolean
  fetchApprovals: typeof fetchApprovals
  fetchAllLocations: typeof fetchAllLocations
  setApprovalSettings: typeof setApprovalSettings
  groupId: number
  setAssetSections: typeof setAssetSections
  setGroupId: typeof setGroupId
  setUsers: typeof setUsers
  users?: Array<IUser>
}

const AppContainer = styled(Paper)`
  max-width: 1200px;
  padding: 1rem;
  margin: 1rem auto;
`

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SectionHeadingsContainer = styled(GridItem)`
  position: sticky;
  top: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MainView: FunctionComponent<IMainView> = ({ isAppReady, approvalsEnabled, approvalSettings, assetSections, fetchApprovals, fetchAllLocations, setApprovalSettings, groupId, setAssetSections, setGroupId, setUsers, users }) => {
  useEffect(() => {
    if (!isAppReady) {
      fetchApprovals({ groupId })
      fetchAllLocations()
      setApprovalSettings({ approvalSettings })
      setUsers({ users })
      setAssetSections({ assetSections })
      setGroupId({ groupId })
    }
  }, [groupId, users, isAppReady])
  return (
    <AppContainer>
      { approvalsEnabled
        ? isAppReady
          ? <GridContainer direction={'column'}>
            <HeaderActions />
            <SectionHeadingsContainer>
              <SectionHeadings />
            </SectionHeadingsContainer>
            <GridItem>
              <UserList />
            </GridItem>
          </GridContainer>
          : <IconContainer>
            <Spinner />
          </IconContainer>
        : <Typography>Approvals are not enabled</Typography>
      }
    </AppContainer>
  )
}

const mapStateToProps = (state: IRootState) => ({
  isAppReady: getIsAppReady()(state),
  approvalsEnabled: getApprovalsEnabled()(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  fetchApprovals,
  fetchAllLocations,
  setApprovalSettings,
  setAssetSections,
  setGroupId,
  setUsers
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MainView)
