import { all } from 'redux-saga/effects'

import approvalsSaga from './ducks/approvals/sagas'
import locationsSaga from './ducks/locations/sagas'

export default function * () {
  yield all([
    approvalsSaga(),
    locationsSaga()
  ])
}
