import { createAction, createReducer, ActionCreatorWithPayload, ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit'
import { ILocation } from '../locations/index'
import { IUser } from '../users/index'

export interface IApprovalsState {
  approvals: Array<IApprovals>
  initialApprovals: Array<IApprovals>
  approvalLevels: IApprovalLevels
  approvalSettings: IApprovalSettings
  updatedApprovals: IUpdatedApprovals
  updatedUserApprovalLocations: Array<ILocation>
}

export interface IApprovals {
  userId?: number
  updatedDate?: number
  userApprovals?: IUserApprovals
}

export interface IApprovalSections {
  [key: string]: boolean
}

export interface IApprovalSettings {
  approvalLevel1?: Array<string>
  approvalLevel2?: Array<string>
  approvalLevel3?: Array<string>
  approvalLevel4?: Array<string>
  approvalLevel5?: Array<string>
  approvalLevel6?: Array<string>
  createdDate?: number
  enabled?: boolean
  groupId?: number
  id?: number
  updatedDate?: number
  updatedTimestamp?: number
}

export interface IApprovalLevels {
  [key: string]: Array<string>
}

export interface IUpdatedApprovals {
  [key: string]: IUpdatedApproval
}

export interface IUpdatedApproval {
  [key: string]: boolean
}

export interface IUserApprovals {
  id?: number
  createdDate?: number
  updatedDate?: number
  user?: IUser
  locations?: Array<ILocation>
  generalInfo?: boolean
  valuation?: boolean
  biddingDetails?: boolean
  makeOffersAndBids?: boolean
  fssSupplies?: boolean
  compliance?: boolean
  updatedTimestamp?: number
  [key: string]: string | number | boolean | IUser | Array<ILocation>
}

export interface IFetchApprovals {
  groupId: number
}

export interface IEditApprovalSection {
  level: string
  userId: number
}

export interface IEditUserLocation {
  locationId: number
}

export interface IFilterApprovalsByLocation {
  locationId: number
}

export interface IDeleteUpdatedApproval {
  userId: number
}

export interface ISetUpdatedUserApprovalLocations {
  locations: Array<ILocation>
}

export interface ISaveUpdatedUserApprovalLocations {
  userId: number
}

export interface IRemoveUserLocation {
  locationId: number
}

export interface ISetApprovalsAction {
  approvals: Array<IApprovals>
}

export interface ISetApprovalAction {
  approval: IApprovals
}

export interface ISetUpdatedApprovalAction {
  userId: number
  approvalSections: IApprovalSections
}

export interface ISetUpdatedApprovalsAction {
  updatedApprovals: {
    [key: number]: IApprovalSections
  }
}

export interface IApprovalSettingsAction {
  approvalSettings: IApprovalSettings
}

export const fetchApprovals: ActionCreatorWithPayload<IFetchApprovals> = createAction('fetchApprovals')
export const editApprovalSection: ActionCreatorWithPayload<IEditApprovalSection> = createAction('editApprovalSection')
export const saveApprovalSections: ActionCreatorWithoutPayload = createAction('saveApprovalSections')
export const deleteUpdatedApproval: ActionCreatorWithPayload<IDeleteUpdatedApproval> = createAction('deleteUpdatedApproval')
export const clearUpdatedApprovals: ActionCreatorWithoutPayload = createAction('clearUpdatedApprovals')
export const editUserLocation: ActionCreatorWithPayload<IEditUserLocation> = createAction('editUserLocation')
export const setUpdatedUserApprovalLocations: ActionCreatorWithPayload<ISetUpdatedUserApprovalLocations> = createAction('setUpdatedUserApprovalLocations')
export const saveUpdatedUserApprovalLocations: ActionCreatorWithPayload<ISaveUpdatedUserApprovalLocations> = createAction('saveUpdatedUserApprovalLocations')
export const removeUserLocation: ActionCreatorWithPayload<IRemoveUserLocation> = createAction('removeUserLocation')
export const setApproval: ActionCreatorWithPayload<ISetApprovalAction> = createAction('setApprovalAction')
export const setApprovals: ActionCreatorWithPayload<ISetApprovalsAction> = createAction('setApprovals')
export const filterApprovalsByLocation: ActionCreatorWithPayload<IFilterApprovalsByLocation> = createAction('filterApprovalsByLocation')
export const resetFilterApprovalsByLocation: ActionCreatorWithoutPayload = createAction('resetFilterApprovalsByLocation')
export const setInitialApprovals: ActionCreatorWithPayload<ISetApprovalsAction> = createAction('setInitialApprovals')
export const setApprovalSettings: ActionCreatorWithPayload<IApprovalSettingsAction> = createAction('setApprovalSettings')
export const setUpdatedApproval: ActionCreatorWithPayload<ISetUpdatedApprovalAction> = createAction('setUpdatedApprovalAction')
export const setUpdatedApprovals: ActionCreatorWithPayload<ISetUpdatedApprovalsAction> = createAction('setUpdatedApprovalsAction')

export const initialState: IApprovalsState = {
  approvals: [],
  initialApprovals: [],
  approvalLevels: {},
  approvalSettings: {},
  updatedApprovals: {},
  updatedUserApprovalLocations: []
}

const reducer = createReducer(initialState, {
  [setApprovals.type]: (state, { payload: { approvals } }: PayloadAction<ISetApprovalsAction>) => {
    state.approvals = approvals
  },
  [setInitialApprovals.type]: (state, { payload: { approvals } }: PayloadAction<ISetApprovalsAction>) => {
    state.initialApprovals = approvals
  },
  [setApproval.type]: (state, { payload: { approval } }: PayloadAction<ISetApprovalAction>) => {
    state.approvals = state.approvals.map(stateApproval => stateApproval.userId === approval.userId ? approval : stateApproval)
  },
  [setUpdatedUserApprovalLocations.type]: (state, { payload: { locations } }: PayloadAction<ISetUpdatedUserApprovalLocations>) => {
    state.updatedUserApprovalLocations = locations
  },
  [setUpdatedApproval.type]: (state, { payload: { userId, approvalSections } }: PayloadAction<ISetUpdatedApprovalAction>) => {
    state.updatedApprovals[String(userId)] = approvalSections
  },
  [setUpdatedApprovals.type]: (state, { payload: { updatedApprovals } }: PayloadAction<ISetUpdatedApprovalsAction>) => {
    state.updatedApprovals = updatedApprovals
  },
  [deleteUpdatedApproval.type]: (state, { payload: { userId } }) => {
    delete state.updatedApprovals[userId]
  },
  [clearUpdatedApprovals.type]: (state) => {
    state.updatedApprovals = {}
  },
  [setApprovalSettings.type]: (state, { payload: { approvalSettings } }: PayloadAction<IApprovalSettingsAction>) => {
    state.approvalSettings = approvalSettings
    const approvalLevels: IApprovalLevels = {}
    for (const [key, value] of Object.entries(approvalSettings)) {
      if (key.includes('approvalLevel') && value.length) approvalLevels[key] = [...value]
    }
    state.approvalLevels = approvalLevels
  }
})

export * from './selectors'
export default reducer
