import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import { IRootState } from '../../store/rootReducer'
import { saveApprovalSections, updatedApprovalsExists } from '../../store/ducks/approvals'
import { getRequestStatus, IRequestStatus } from '../../store/ducks/apiRequestsStatuses'
import SaveButton from './SaveButton'

export interface ISaveApprovals {
  updatedApprovalsSelected: boolean
  requestStatus: IRequestStatus
  saveApprovalSections: typeof saveApprovalSections
}

export const SaveApprovals: FunctionComponent<ISaveApprovals> = ({ requestStatus, saveApprovalSections, updatedApprovalsSelected }) => {
  return (
    <>
      <SaveButton
        disabled={!updatedApprovalsSelected}
        handleSaveAction={saveApprovalSections}
        isPending={requestStatus.isPending}
      />
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  updatedApprovalsSelected: updatedApprovalsExists()(state),
  requestStatus: getRequestStatus(saveApprovalSections.type)(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  saveApprovalSections
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SaveApprovals)
