import request from 'superagent'

import { IApprovals } from '../store/ducks/approvals'

export const getApprovals = (groupId: number): Promise<IApprovals[]> => (
  request
    .get(`/assets/api/userapprovals/${groupId}`)
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to get users')
    })
)

export const setUserLocations = (data: IApprovals, groupId: number): Promise<IApprovals> => (
  request
    .put(`/assets/api/userapprovals/${groupId}/locations`)
    .send(data)
    .then((res) => res.body)
)

export const setUserApprovalSection = (data: Array<IApprovals>, groupId: number): Promise<IApprovals> => (
  request
    .put(`/assets/api/userapprovals/${groupId}`)
    .send(data)
    .then((res) => res.body)
)
