import { ILocation } from '../store/ducks/locations'
import { entities } from './string'

export const consolidateLocationLevels = (location: ILocation) => {
  let locationLevels: string
  for (const [key, value] of Object.entries(location)) {
    if (key.includes('level') && value) key === 'level1' ? locationLevels = entities.decode(value) : locationLevels += ` / ${entities.decode(value)}`
  }
  return locationLevels
}

export interface ISectionMappings {
  [key: string]: string
}

export const SECTION_MAPPINGS: ISectionMappings = {
  BIDDING_DETAILS: 'biddingDetails',
  COMPLIANCE: 'compliance',
  FSS_SUPPLIES: 'fssSupplies',
  GENERAL_INFO: 'generalInfo',
  MAKE_OFFER_AND_BIDS: 'makeOffersAndBids',
  VALUATION: 'valuation'
}
