import request from 'superagent'

import { ILocation } from '../store/ducks/locations'

export const getAvailableLocations = (groupId: number): Promise<ILocation[]> => (
  request
    .get(`/assets/api/locations?groupId=${groupId}&sort=lower(item.level1),asc&sort=lower(item.level2),asc&sort=lower(item.level3),asc&sort=lower(item.level4),asc&sort=lower(item.level5),asc&sort=lower(item.level6),asc`)
    .then((res) => res.body.content)
    .catch(() => {
      throw new Error('Failed to get locations')
    })
)
