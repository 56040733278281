import React, { FunctionComponent, useEffect, useState } from 'react'

import { Button } from 'bdx-af-ui/core'

export interface ISaveButton {
  isPending: boolean
  handleSaveAction: any
  disabled: boolean
}

export const SaveButton: FunctionComponent<ISaveButton> = ({ isPending, handleSaveAction, disabled }) => {
  const [title, setTitle] = useState('')
  useEffect(() => {
    disabled
      ? setTitle('Nothing to save')
      : setTitle('Save updated items')
  }, [disabled, isPending])
  const handleButtonClick = () => handleSaveAction()
  return (
    <>
      <Button
        isDisabled={isPending || disabled}
        disabled={isPending || disabled}
        onClick={handleButtonClick}
        title={title}
        variant={'contained'}
      >
        {isPending
          ? 'SAVING...'
          : 'SAVE'
        }
      </Button>
    </>
  )
}

export default SaveButton
