import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { GridItem, Paper, Typography } from 'bdx-af-ui/core'
import { Close, Spinner } from 'bdx-af-ui/icons'

import { entities } from '../../utils/string'
import { IRootState } from '../../store/rootReducer'
import { ILocation } from '../../store/ducks/locations'
import { removeUserLocation, getUpdatedUserLocations, saveUpdatedUserApprovalLocations } from '../../store/ducks/approvals'
import { getRequestStatus, IRequestStatus } from '../../store/ducks/apiRequestsStatuses'
import { consolidateLocationLevels } from '../../utils/locations'

export interface ILocationsList {
  removeUserLocation: typeof removeUserLocation
  saveRequestStatus: IRequestStatus
  updatedLocations: Array<ILocation>
}

const LocationContainer = styled(Paper)`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 150px;
  overflow: hidden;
`

const LevelsContainer = styled.div`
  padding: 0.5rem;
  height: 130px;
  overflow: hidden;
 `

const RemoveLocation = styled.div`
  position: absolute;
  top: 0.2;
  right: 0.4rem;
  cursor: pointer;
`

const LocationLevelContainer = styled(Typography)`
  margin: 0;
`

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LocationsList: FunctionComponent<ILocationsList> = ({ removeUserLocation, updatedLocations, saveRequestStatus }) => {
  const removeLocation = (locationId: number) => removeUserLocation({ locationId })
  return (
    <>
      { saveRequestStatus.isPending
        ? <IconContainer>
          <Spinner />
        </IconContainer>
        : updatedLocations?.length > 0
          ? (
            updatedLocations.map((location, index) => {
              return <GridItem
                key={`assigned_location_${index}`}
                lg={2}
                md={4}
                xs={6}
              >
                <LocationContainer title={consolidateLocationLevels(location)}>
                  <LevelsContainer>
                    {getLevels(location)}
                  </LevelsContainer>
                  <RemoveLocation data-testid={`remove-user-location-${index}`} onClick={() => removeLocation(location.id)}>
                    <Close width={'10px'} height={'10px'} />
                  </RemoveLocation>
                </LocationContainer>
              </GridItem>
            })
          )
          : <GridItem xs={12}><Typography data-testid={'no-locations-text'} align={'center'}>No Locations Assigned</Typography></GridItem>
      }
    </>
  )
}

const getLevels = (location: ILocation) => {
  const locationLevels = []
  for (const [key, value] of Object.entries(location)) {
    if (key.includes('level') && value) {
      locationLevels.push(<LocationLevelContainer align={'left'} key={`location_level_${key}_${value}`} variant={'body2'}>{entities.decode(value)}</LocationLevelContainer>)
    }
  }
  return locationLevels
}

const mapStateToProps = (state: IRootState) => ({
  updatedLocations: getUpdatedUserLocations()(state),
  saveRequestStatus: getRequestStatus(saveUpdatedUserApprovalLocations.type)(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  removeUserLocation
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LocationsList)
