import { createAction, createReducer, ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'

export interface IConfigsState {
  assetSections: IAssetSections
  groupId: number
}

export interface IAssetSections {
  [key: string]: string
}

export interface IAssetSectionsAction {
  assetSections: IAssetSections
}

export interface ISetGroupIdAction {
  groupId: number
}

export const setAssetSections: ActionCreatorWithPayload<IAssetSectionsAction> = createAction('setAssetSections')
export const setGroupId: ActionCreatorWithPayload<ISetGroupIdAction> = createAction('setGroupId')

export const initialState: IConfigsState = {
  assetSections: {},
  groupId: null
}

const reducer = createReducer(initialState, {
  [setAssetSections.type]: (state, { payload: { assetSections } }: PayloadAction<IAssetSectionsAction>) => {
    state.assetSections = assetSections
  },
  [setGroupId.type]: (state, { payload: { groupId } }: PayloadAction<ISetGroupIdAction>) => {
    state.groupId = groupId
  }
})

export * from './selectors'
export default reducer
