import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Autocomplete, BLANK_SUGGESTION, suggestion } from 'bdx-af-ui/core'
import { Close, MagnifyingGlassClear } from 'bdx-af-ui/icons'

import { ILocation } from '../../store/ducks/locations'
import { consolidateLocationLevels } from '../../utils/locations'
import { compare } from '../../utils/array'

export interface ILocationSuggestion {
  availableLocations: Array<ILocation>
  handleLocationUpdate?(arg0: (arg0: any) => void, arg1: suggestion): void
  handleOptionReset?(arg0: (arg0: any) => void): void
  placeholder: string
  showSelection: boolean
  width?: string
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`

const ResetIconContainer = styled(IconContainer)`
  cursor: pointer;
`

const MagnifyingGlassContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-left: 2px;
`

export const LocationSuggestion: FunctionComponent<ILocationSuggestion> = ({ availableLocations, handleLocationUpdate, handleOptionReset, showSelection, width = '165px', placeholder }) => {
  const [selectedOption, setSelectedOption] = useState<suggestion>(BLANK_SUGGESTION)
  const [suggestions, setSuggestions] = useState([])
  useEffect(() => {
    if (availableLocations.length > 0) setSuggestions(convertLocationsIntoSuggestion())
  }, [availableLocations])
  const convertLocationsIntoSuggestion = () => availableLocations
    .map(location => ({ value: location.id, label: consolidateLocationLevels(location) }))
    .sort((a, b) => compare(a, b, 'label'))
  const handleOptionSelect = (selectSuggestion: suggestion) => handleLocationUpdate(setSelectedOption, selectSuggestion)
  const optionReset = () => handleOptionReset(setSelectedOption)
  return (
    <>
      { suggestions &&
        <Autocomplete
          suggestions={suggestions}
          selectedSuggestion={selectedOption}
          onSuggestionChange={handleOptionSelect}
          disabled={suggestions.length === 0}
          placeholder={suggestions.length === 0 ? 'No locations available' : placeholder}
          textFieldProps={{
            ...(showSelection && (selectedOption !== BLANK_SUGGESTION) && {
              endAdornment: <ResetIconContainer onClick={optionReset} data-testid={'reset-suggestion-selection'}><Close width={'0.8rem'} height={'0.8rem'} /></ResetIconContainer>
            }),
            labelProps: {
              display: 'none'
            },
            baseInputProps: {
              width
            },
            startAdornment: <MagnifyingGlassContainer><MagnifyingGlassClear fillColor='#000000' fillOpacity={'0.8'} width={'0.8rem'} height={'0.8rem'} viewBox={'0 0 136 136'} /></MagnifyingGlassContainer>
          }}
          downshiftProps={!showSelection ? { onChange: handleOptionSelect } : undefined}
        />
      }
    </>
  )
}

export default LocationSuggestion
