import React, { FunctionComponent } from 'react'
// import TagManager from 'react-gtm-module'
import { ToastProvider } from 'react-toast-notifications'

import createProvider from './components/Error/ErrorBoundary'
import MainView from './components/MainView'
import { IAssetSections } from './store/ducks/configs'
import { IApprovalSettings } from './store/ducks/approvals'
import { IUser } from './store/ducks/users'
import rootReducer from './store/rootReducer'
import rootSaga from './store/rootSaga'

export interface IAppProps {
  approvalSettings?: IApprovalSettings
  assetSections?: IAssetSections
  groupId?: number
  users?: Array<IUser>
}

// const tagManagerArgs = {
//   gtmId: 'GTM-5CSHQ6S'
// }

const AppProvider = createProvider(rootReducer, rootSaga)

// TagManager.initialize(tagManagerArgs)

const App: FunctionComponent<IAppProps> = ({ approvalSettings, assetSections, groupId, users }) => (
  <ToastProvider autoDismiss>
    <AppProvider>
      <MainView approvalSettings={approvalSettings} assetSections={assetSections} groupId={groupId} users={users} />
    </AppProvider>
  </ToastProvider>
)

export default App
