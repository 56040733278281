import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import { fetchAvailableLocations, IFetchAvailableLocations, fetchAllLocations, ILocation, setAllLocations, setAvailableLocations, setInitialAvailableLocations } from './index'
import { getLocationsByUserId } from '../approvals/'
import { getAvailableLocations } from '../../../api/locations'
import { handleAsyncCall } from '../apiRequestsStatuses/sagas'
import { getGroupId } from '../configs'

export function* fetchAvailableLocationsSaga(action: PayloadAction<IFetchAvailableLocations>) {
  let availableLocations: Array<ILocation> = yield handleAsyncCall(fetchAvailableLocations.type, getAvailableLocations, action.payload.groupId)
  const userLocations: Array<ILocation> = yield select(getLocationsByUserId(action.payload.userId))
  if (userLocations.length !== 0) availableLocations = yield availableLocations.filter(location => !userLocations.find(userLocation => userLocation.id === location.id))
  yield put(setAvailableLocations({ availableLocations }))
  yield put(setInitialAvailableLocations({ availableLocations }))
}

export function* fetchAllLocationsSaga() {
  const groupId = yield select(getGroupId())
  const locations: Array<ILocation> = yield handleAsyncCall(fetchAllLocations.type, getAvailableLocations, groupId)
  yield put(setAllLocations({ locations }))
}

export default function* () {
  yield takeEvery(fetchAvailableLocations.type, fetchAvailableLocationsSaga)
  yield takeLatest(fetchAllLocations.type, fetchAllLocationsSaga)
}
