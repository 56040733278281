import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { GridContainer, GridItem, Typography } from 'bdx-af-ui/core'
import { Spinner } from 'bdx-af-ui/icons'
import styled from 'styled-components'

import { IRootState } from '../../store/rootReducer'
import { IApprovals, IApprovalLevels, getApprovals, getApprovalLevels, saveApprovalSections } from '../../store/ducks/approvals'
import { getRequestStatus, IRequestStatus } from '../../store/ducks/apiRequestsStatuses'
import LocationsModal from '../Locations/LocationsModal'
import User from './User'

export interface IApprovalsList {
  approvals: Array<IApprovals>
  approvalLevels: IApprovalLevels
  requestStatus: IRequestStatus
}

interface IUserContainerProps {
  isEven: boolean
}

const UserContainer = styled.div <IUserContainerProps>`
  background-color: ${({ isEven }) => isEven && 'rgba(0, 0, 0, 0.08)'};
  padding: 1rem;
`

const UserGridContainer = styled(GridContainer)`
  display: inline-block;
`

const UserGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserList: FunctionComponent<IApprovalsList> = ({ approvals, approvalLevels, requestStatus }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userId, setUserId] = useState(null)
  const handleIconClick = (userId: number) => {
    setUserId(userId)
    setIsModalOpen(true)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <LocationsModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        userId={userId}
      />
      {requestStatus.isPending
        ? <IconContainer>
          <Spinner />
        </IconContainer>
        : approvals.length > 0
          ? approvals.map((approval, index) => (
            <UserGridContainer
              direction={'column'}
              key={`user_${approval.userId}`}
              spacing={0}
            >
              <UserGridItem>
                <UserContainer isEven={(index % 2) === 0}>
                  <User
                    userId={approval.userId}
                    approvalLevels={approvalLevels}
                    handleModal={handleIconClick}
                  />
                </UserContainer>
              </UserGridItem>
            </UserGridContainer>
          ))
          : <Typography align={'center'}><strong>No users available</strong></Typography>
      }
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  approvals: getApprovals()(state),
  approvalLevels: getApprovalLevels()(state),
  requestStatus: getRequestStatus(saveApprovalSections.type)(state)
})

export default connect(mapStateToProps)(UserList)
