import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { BLANK_SUGGESTION, GridContainer, GridItem, suggestion } from 'bdx-af-ui/core'
import styled from 'styled-components'

import { IRootState } from '../../store/rootReducer'
import LocationSuggestion from '../Locations/LocationSuggestion'
import SaveApprovals from './SaveApprovals'
import { getAllLocations, ILocation } from '../../store/ducks/locations'
import { filterApprovalsByLocation, resetFilterApprovalsByLocation, updatedApprovalsExists } from '../../store/ducks/approvals'

const SaveContainer = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

export interface IHeaderActions {
  filterApprovalsByLocation: typeof filterApprovalsByLocation
  resetFilterApprovalsByLocation: typeof resetFilterApprovalsByLocation
  locations: Array<ILocation>
  updatedApprovalsSelected: boolean
}

type setSelectedOptionType = (arg0: suggestion) => void

export const HeaderActions: FunctionComponent<IHeaderActions> = ({ filterApprovalsByLocation, locations, resetFilterApprovalsByLocation, updatedApprovalsSelected }) => {
  const handleLocationUpdate = (setSelectedOption: setSelectedOptionType, selectSuggestion: suggestion) => {
    if (getConfirmation('Unsaved changes may be lost, would you like to continue?')) {
      setSelectedOption(selectSuggestion)
      filterApprovalsByLocation({ locationId: Number(selectSuggestion.value) })
    }
  }
  const handleOptionReset = (setSelectedOption: setSelectedOptionType) => {
    if (getConfirmation('You have unsaved changes, would you like to continue?')) {
      resetFilterApprovalsByLocation()
      setSelectedOption(BLANK_SUGGESTION)
    }
  }
  const getConfirmation = (confirmText: string) => updatedApprovalsSelected ? confirm(confirmText) : true
  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <LocationSuggestion
          availableLocations={locations}
          handleLocationUpdate={handleLocationUpdate}
          handleOptionReset={handleOptionReset}
          placeholder={'Filter by assigned location...'}
          showSelection={true}
          width={'25rem'}
        />
      </GridItem>
      <SaveContainer xs={12} md={6}>
        <SaveApprovals />
      </SaveContainer>
    </GridContainer>
  )
}

const mapStateToProps = (state: IRootState) => ({
  locations: getAllLocations()(state),
  updatedApprovalsSelected: updatedApprovalsExists()(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  filterApprovalsByLocation,
  resetFilterApprovalsByLocation
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HeaderActions)
