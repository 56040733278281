import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { GridContainer, GridItem, Typography } from 'bdx-af-ui/core'

import { IRootState } from '../../store/rootReducer'
import { IUser, getUserById } from '../../store/ducks/users'
import { IApprovals, IApprovalLevels, getApprovalByUserId } from '../../store/ducks/approvals'
import SectionCheckbox from './SectionCheckbox'
import UserProfileIcon from './UserProfileIcon'

export interface IUserComp {
  approvals: IApprovals
  approvalLevels: IApprovalLevels
  user?: IUser
  userId: number
  handleModal: any
}

export const User: FunctionComponent<IUserComp> = ({ approvals, approvalLevels, handleModal, user }) => {
  const locationLength = approvals?.userApprovals?.locations?.length || 0
  const handleModalClick = () => handleModal(user.id)
  return (
    <>
      {user &&
        <GridContainer>
          <GridItem xs={4}>
            <GridContainer>
              <GridItem xs={2}>
                <UserProfileIcon
                  firstName={user.firstName}
                  lastName={user.lastName}
                  userId={user.id}
                />
              </GridItem>
              <GridItem xs={10}>
                <GridContainer
                  direction={'column'}
                >
                  <GridItem>
                    <Typography
                      align={'left'}
                      marginBottom={'0'}
                      variant={'body2'}
                    >
                      <strong>{user.firstName} {user.lastName}</strong>
                    </Typography>
                    <a onClick={handleModalClick} data-testid={'open-user-loctations-modal'}>
                      <Typography
                        align={'left'}
                        color={'secondary'}
                        marginBottom={'0'}
                        variant={'body2'}
                      >
                        {locationLength} LOCATIONS
                      </Typography>
                    </a>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          { Object.keys(approvalLevels).map(level => {
            return <GridItem key={`approvalLevel_${level}`}>
              <SectionCheckbox
                userId={user.id}
                level={level}
              />
            </GridItem>
          })}
        </GridContainer>
      }
    </>
  )
}

const mapStateToProps = (state: IRootState, props: Partial<IUserComp>) => ({
  approvals: getApprovalByUserId(props.userId)(state),
  user: getUserById(props.userId)(state)
})

export default connect(mapStateToProps)(User)
