export const compare = (a: any, b: any, param: string) => {
  if (a[param] < b[param]) return -1
  if (a[param] > b[param]) return 1
  return 0
}

export const checkForEquality = (args: Array<unknown>) => (
  args.every((v, i, a) =>
    v === a[0] &&
    v !== null
  )
)
