import { createAction, createReducer, ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'

export interface IUsersState {
  users: Array<IUser>
}

export interface IAuthorities {
  id?: number
  createdDate?: number
  updatedDate?: number
  role?: string
  updatedTimestamp?: number
}

export interface IProfile {
  id?: number
  createdDate?: number
  updatedDate?: number
  honorific?: null
  companyName?: string
  jobTitle?: string
  address?: string
  phone?: number
  ext?: null
  mobile?: number
  companyType?: string
  expertises?: null
  biography?: null
  language?: string
  profileImage?: null
  updatedTimestamp?: number
}

export interface IUser {
  accountNonExpired?: boolean
  accountNonLocked?: boolean
  approvalManager?: boolean
  authorities?: Array<IAuthorities>
  created?: number
  createdDate?: number
  credentialsNonExpired?: boolean
  email?: string
  enabled?: boolean
  firstName?: string
  hasSelectedDefaultGroup?: boolean
  id?: number
  lastLogin?: number
  lastName?: string
  loggedIn?: boolean
  profile?: IProfile
  source?: string
  superSystemAdmin?: boolean
  systemAccount?: boolean
  tag?: null
  updatedDate?: number
  updatedTimestamp?: number
  username?: string
}

export interface ISetUsersAction {
  users: Array<IUser>
}

export const setUsers: ActionCreatorWithPayload<ISetUsersAction> = createAction('setUsers')

export const initialState: IUsersState = {
  users: []
}

const reducer = createReducer(initialState, {
  [setUsers.type]: (state, { payload: { users } }: PayloadAction<ISetUsersAction>) => {
    state.users = users
  }
})

export * from './selectors'
export default reducer
