import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { GridContainer, GridItem, Typography } from 'bdx-af-ui/core'

import { IRootState } from '../../store/rootReducer'
import { IApprovalLevels, getApprovalLevels } from '../../store/ducks/approvals'
import { getAssetSections, IAssetSections } from '../../store/ducks/configs'

export interface ISectionHeadings {
  approvalLevels?: IApprovalLevels
  assetSections?: IAssetSections
}

export const SectionHeadings: FunctionComponent<ISectionHeadings> = ({ assetSections, approvalLevels }) => {
  return (
    <GridContainer>
      <GridItem xs={4}>
      </GridItem>
      { approvalLevels && assetSections && Object.keys(approvalLevels).map((level) =>
        <GridItem key={level}>
          <Typography
            align={'center'}
            marginBottom={'0'}
            variant={'body1'}
          >
            {getAssetSectionDisplay(approvalLevels[level], assetSections)}
          </Typography>
        </GridItem>
      )}
    </GridContainer>
  )
}

const getAssetSectionDisplay = (levels: Array<string>, assetSections: IAssetSections) => {
  let letLevelText = ''
  levels.forEach((level: string, index: number) => {
    letLevelText += index !== (levels.length - 1) ? `${assetSections[level]} / ` : assetSections[level]
  })
  return letLevelText
}

const mapStateToProps = (state: IRootState) => ({
  approvalLevels: getApprovalLevels()(state),
  assetSections: getAssetSections()(state)
})

export default connect(mapStateToProps)(SectionHeadings)
