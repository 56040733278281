import { createAction, createReducer, ActionCreatorWithPayload, ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit'

export interface ILocationsState {
  allLocations: Array<ILocation>
  availableLocations: Array<ILocation>
  filterUsersByLocationId: number | null
  initialAvailableLocations: Array<ILocation>
}

export interface ILocation {
  id?: number
  createdDate?: number
  updatedDate?: number
  level1?: string
  level2?: string
  level3?: string
  level4?: string
  level5?: string
  level6?: string
  addressLine1?: string
  addressLine2?: string
  postCode?: string
  country?: string
  city?: string
  county?: string
  latitude?: string
  longitude?: string
  updatedTimestamp?: number
}

export interface ISetAvailableLocationsAction {
  availableLocations: Array<ILocation>
}

export interface ISetAllLocationsAction {
  locations: Array<ILocation>
}

export interface ISetInitialAvailableLocations {
  availableLocations: Array<ILocation>
}

export interface IAddAvailableLocationAction {
  location: ILocation
}

export interface IRemoveAvailableLocationAction {
  location: ILocation
}

export interface IFetchAvailableLocations {
  groupId: number
  userId: number
}

export interface ISetFilterUsersByLocationIdAction {
  locationId: number
}

export const fetchAvailableLocations: ActionCreatorWithPayload<IFetchAvailableLocations> = createAction('fetchAvailableLocations')
export const fetchAllLocations: ActionCreatorWithoutPayload = createAction('fetchAllLocations')
export const setAvailableLocations: ActionCreatorWithPayload<ISetAvailableLocationsAction> = createAction('setAvailableLocations')
export const setAllLocations: ActionCreatorWithPayload<ISetAllLocationsAction> = createAction('setAllLocations')
export const setInitialAvailableLocations: ActionCreatorWithPayload<ISetInitialAvailableLocations> = createAction('setInitialAvailableLocations')
export const addAvailableLocation: ActionCreatorWithPayload<IAddAvailableLocationAction> = createAction('addUserLocation')
export const removeAvailableLocation: ActionCreatorWithPayload<IRemoveAvailableLocationAction> = createAction('removeAvailableLocation')
export const setFilterUsersByLocationId: ActionCreatorWithPayload<ISetFilterUsersByLocationIdAction> = createAction('filterUsersByLocationId')

export const initialState: ILocationsState = {
  allLocations: [],
  availableLocations: [],
  filterUsersByLocationId: null,
  initialAvailableLocations: []
}

const reducer = createReducer(initialState, {
  [setAvailableLocations.type]: (state, { payload: { availableLocations } }: PayloadAction<ISetAvailableLocationsAction>) => {
    state.availableLocations = availableLocations
  },
  [setAllLocations.type]: (state, { payload: { locations } }: PayloadAction<ISetAllLocationsAction>) => {
    state.allLocations = locations
  },
  [setInitialAvailableLocations.type]: (state, { payload: { availableLocations } }: PayloadAction<ISetInitialAvailableLocations>) => {
    state.initialAvailableLocations = availableLocations
  },
  [addAvailableLocation.type]: (state, { payload: { location } }: PayloadAction<IRemoveAvailableLocationAction>) => {
    const shouldAdd = state.availableLocations.every((availableLocation) => availableLocation.id !== location.id)
    state.availableLocations = shouldAdd ? [...state.availableLocations, location] : state.availableLocations
  },
  [removeAvailableLocation.type]: (state, { payload: { location } }: PayloadAction<IRemoveAvailableLocationAction>) => {
    state.availableLocations = state.availableLocations.filter(availableLocation => availableLocation.id !== location.id)
  },
  [setFilterUsersByLocationId.type]: (state, { payload: { locationId } }: PayloadAction<ISetFilterUsersByLocationIdAction>) => {
    state.filterUsersByLocationId = locationId
  }
})

export * from './selectors'
export default reducer
