import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'

const locationsSelector = (state: IRootState) => state[KEYS.LOCATIONS]

export const getLocationById = (locationId: number) => createSelector(
  locationsSelector,
  (state) => state.availableLocations.filter(location => location.id === locationId)[0]
)

export const getInitialAvailableLocations = () => createSelector(
  locationsSelector,
  (state) => state.initialAvailableLocations
)

export const getAvailableLocations = () => createSelector(
  locationsSelector,
  (state) => state.availableLocations
)

export const getAllLocations = () => createSelector(
  locationsSelector,
  (state) => state.allLocations
)

export const getFilterUsersByLocationId = () => createSelector(
  locationsSelector,
  (state) => state.filterUsersByLocationId
)
